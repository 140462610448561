<template>
    <div class="rf-view background customers-view">
        <NavigationBar :title="$t('customers.title')">
            <BackButton v-if="canPop" slot="left" @click="pop" />
            <button v-if="false" slot="right" class="button text" @click="createNew">
                {{ $t('buttons.add') }}
            </button>
        </NavigationBar>

        <div v-if="isLoading" class="loading-overlay">
            <Spinner />
            <p>{{ $t('customers.exportingMessage') }}</p>
        </div>

        <main>
            <h1>{{ $t('customers.title') }}</h1>

            <GeneralErrorsView :error-box="errorBox" />

            <SegmentedControl v-model="selectedTab" :items="tabs" :labels="tabLabels" :badges="badges" />

            <label class="input-with-icon">
                <span class="icon search" />
                <input class="input" name="search" :placeholder="$t('customers.placeholders.search').toString()" type="search" inputmode="search" enterkeyhint="search" autocorrect="off" autocomplete="off" spellcheck="false" autocapitalize="off" @input="searchQuery = $event.target.value">
            </label>

            <Spinner v-if="loading" />
            <template v-else>
                <template v-if="filteredCustomers.length > 0">
                    <table :key="selectedTab" class="data-table hide-smartphone">
                        <thead>
                            <tr>
                                <th v-for="column of columns" :key="column.id" :class="{'small': column.small }" @click="toggleSort(column)">
                                    <span>{{ column.name }}</span>
                                    <span v-if="sortBy === column && sortDirection == 'ASC'" class="icon arrow-up-small gray" />
                                    <span v-if="sortBy === column && sortDirection == 'DESC'" class="icon arrow-down-small gray" />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="customer in sortedCustomers" :key="customer.id" class="selectable" @click="editCustomer(customer)">
                                <td v-for="column of columns" :key="column.id" :class="{'small': column.small }">
                                    {{ column.getString(customer) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <List :key="selectedTab+'-list'" class="only-smartphone">
                        <ListItem v-for="customer in sortedCustomers" :key="customer.id" :selectable="true" @click="editCustomer(customer)">
                            <h3 class="style-h4">
                                {{ customer.settings.businessName }}
                                {{ customer.settings.city ? "("+customer.settings.city+")" : "" }}
                            </h3>
                            <p class="style-description">
                                {{ customer.settings.contactName }}
                            </p>
                            <span slot="right" class="icon arrow-right gray" />
                        </ListItem>
                    </List>
                </template>
                <p v-else class="error-box">
                    {{ $t('customers.emptyDescription') }}
                </p>
            </template>

            <button type="button" class="primary button" :class="{ 'loading-cursor': isLoading }" @click="downloadExcel(false)">
                {{ $t('customers.exportCustomers') }}
            </button>

            <button v-if="this.isAdmin" type="button" class="primary button" :class="{ 'loading-cursor': isLoading }" @click="downloadExcel(true)">
                {{ $t('customers.exportAllCustomers') }}
            </button>
            
        </main>
    </div>
</template>

<script lang="ts">
import { BackButton, InputBox, List, ListItem, NavigationBar, SegmentedControl,Spinner, Toolbar } from "@restofrit/components"
import { Session } from "@restofrit/networking";
import { CustomerPrivate, CustomerStatus, Order, WholesalerPrivate } from "@restofrit/structures";
import { Formatter } from "@restofrit/utilities";
import { ArrayDecoder, AutoEncoderPatchType, Decoder, PatchableArray, PatchableArrayAutoEncoder } from "@simonbackx/simple-encoding";
import { ErrorBox, GeneralErrorsView } from "@simonbackx/simple-error-forms";
import { ComponentWithProperties, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";

import CustomerEditView from "./CustomerEditView.vue";
import { exportCustomerStats } from "./CustomersExcelExporter";

const tabs = ["waiting", "all", "declined"] as const
type TabType = typeof tabs[number]

class SortableColumn {
    id: string
    name: string
    sort: (a: CustomerPrivate, b: CustomerPrivate) => number
    getString: (customer: CustomerPrivate) => string
    small = false

    constructor(data: {
        id: string, 
        name: string, 
        sort: (a: CustomerPrivate, b: CustomerPrivate) => number,
        getString: (customer: CustomerPrivate) => string,
        small?: boolean
    }) {
        this.id = data.id
        this.name = data.name
        this.sort = data.sort
        this.getString = data.getString
        if (data.small !== undefined) {
            this.small = data.small
        }
    }
}

@Component({
    components: {
        NavigationBar,
        InputBox,
        List,
        ListItem,
        BackButton,
        GeneralErrorsView,
        Spinner,
        SegmentedControl
    },
})
export default class CustomersView extends Mixins(NavigationMixin) {
    customers: CustomerPrivate[] = []
    errorBox: ErrorBox | null = null
    loading = false

    @Prop({ required: true })
    wholesaler: WholesalerPrivate

    isAdmin: boolean = Session.shared.user?.permissions?.isAdmin ?? false;

    tabs = tabs
    tabLabels = [this.$t('customers.toAcceptTitle').toString(), this.$t('customers.acceptedTitle').toString(), this.$t('customers.blocked').toString()];

    searchQuery = ""

    isLoading: boolean = false;

    selectedTab: TabType = this.tabs[0]

    @Watch("selectedTab")
    onTabChanged() {
        this.sortBy = this.columns.find(c => c.id === this.sortBy.id) ?? this.columns[0]
    }

    get columns() {
        const def = [
            new SortableColumn({
                id: "businessName", 
                name: this.$t('customers.placeholders.businessName').toString(),
                sort: (a, b) => a.settings.businessName.localeCompare(b.settings.businessName),
                getString: (customer) => customer.settings.businessName
            }),
            new SortableColumn({
                id: "contactName", 
                name: this.$t('customers.placeholders.contactName').toString(),
                sort: (a, b) => a.settings.contactName.localeCompare(b.settings.contactName),
                getString: (customer) => customer.settings.contactName,
                small: true
            }),
            new SortableColumn({
                id: "email", 
                name: this.$t('customers.placeholders.email').toString(),
                sort: (a, b) => a.user.email.localeCompare(b.user.email),
                getString: (customer) => customer.user.email
            }),
            new SortableColumn({
                id: "phone", 
                name: this.$t('customers.placeholders.contactPhone').toString(),
                sort: (a, b) => a.settings.contactPhone.localeCompare(b.settings.contactPhone),
                getString: (customer) => customer.settings.contactPhone,
                small: true
            }),
        ];

        if (this.selectedTab === "waiting"){
            return [
                ...def,
                new SortableColumn({
                    id: "createdAt", 
                    name: this.$t('customers.placeholders.since').toString(),
                    sort: (a, b) => a.createdAt.getTime() - b.createdAt.getTime(),
                    getString: (customer) => Formatter.date(customer.createdAt),
                    small: true
                })
            ]
        }
        return [
            ...def,
            new SortableColumn({
                id: "number", 
                name: this.$t('customers.placeholders.clientNumber').toString(),
                sort: (a, b) => a.settings.number.localeCompare(b.settings.number),
                getString: (customer) => customer.settings.number,
                small: true
            })
        ]
    }

    sortBy: SortableColumn = this.columns[0]
    sortDirection: "ASC" | "DESC" = "ASC"

    mounted() {
        this.load().catch(e => {
            console.error(e)
        })
    }

    get badges() {
        return [this.toAcceptCustomers.length > 0 ? this.toAcceptCustomers.length.toString() : "", "", ""]
    }

    toggleSort(column: SortableColumn) {
        if (this.sortBy === column) {
            this.sortDirection = this.sortDirection == "ASC" ? "DESC" : "ASC"
        } else {
            this.sortBy = column
            this.sortDirection = "ASC"
        }
    }

    // For an unknown reason, Typescript isn't smart enough to detect that we always return a value...
    // eslint-disable-next-line getter-return
    get tabCustomers() {
        switch (this.selectedTab) {
            case "all":
                return this.customers.filter(c => c.settings.status == CustomerStatus.Accepted)
            case "waiting":
                return this.customers.filter(c => c.settings.status == CustomerStatus.Pending)
            case "declined":
                return this.customers.filter(c => c.settings.status == CustomerStatus.Declined || c.settings.status == CustomerStatus.Blocked)
        }
    }

    get filteredCustomers() {
        if (this.searchQuery.length > 0) {
            return this.tabCustomers.filter(customer => {
                return customer.settings.businessName.toLowerCase().includes(this.searchQuery.toLowerCase())
                    || customer.settings.contactName.toLowerCase().includes(this.searchQuery.toLowerCase())
                    || customer.user.email.toLowerCase().includes(this.searchQuery.toLowerCase())
                    || customer.settings.contactPhone.replace(/\s+/g, "").toLowerCase().includes(this.searchQuery.toLowerCase())
                    || customer.settings.number.replace(/\s+/g, "").toLowerCase().includes(this.searchQuery.toLowerCase())
            })  
        }

        return this.tabCustomers
    }

    get sortedCustomers() {
        if (!this.sortBy) {
            return this.filteredCustomers
        }

        return this.filteredCustomers.sort((a, b) => {
            if (this.sortDirection == "ASC") {
                return this.sortBy.sort(a, b)
            } else {
                return this.sortBy.sort(b, a)
            }
        })
    }

    get toAcceptCustomers() {
        return this.customers.filter(c => c.settings.status == CustomerStatus.Pending)
    }

    get acceptedCustomers() {
        return this.customers.filter(c => c.settings.status === CustomerStatus.Accepted)
    }

    async load() {
        this.loading = true
        try {
            const response = await Session.shared.authenticatedServer.request({
                method: "GET",
                path: "/dashboard/wholesalers/"+this.wholesaler.id+"/customers",
                decoder: new ArrayDecoder(CustomerPrivate as Decoder<CustomerPrivate>)
            })
            this.customers = response.data
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.loading = false
    }

    async patchCustomers(patch: PatchableArrayAutoEncoder<CustomerPrivate>) {
        const response = await Session.shared.authenticatedServer.request({
            method: "PATCH",
            path: "/dashboard/wholesalers/"+this.wholesaler.id+"/customers",
            body: patch,
            decoder: new ArrayDecoder(CustomerPrivate as Decoder<CustomerPrivate>)
        })
        this.customers = response.data
    }
    
    createNew() {
        // todo: also create a new user (not yet possible)
        const customer = CustomerPrivate.create({})
        this.show(new ComponentWithProperties(CustomerEditView, {
            customer,
            wholesaler: this.wholesaler,
            callback: async (patch: AutoEncoderPatchType<CustomerPrivate>) => {
                const arr: PatchableArrayAutoEncoder<CustomerPrivate> = new PatchableArray()
                arr.addPut(customer.patch(patch))
                await this.patchCustomers(arr)
            }
        }))
    }

    editCustomer(customer: CustomerPrivate) {
        this.show(new ComponentWithProperties(CustomerEditView, {
            customer,
            wholesaler: this.wholesaler,
            callback: async (patch: AutoEncoderPatchType<CustomerPrivate>) => {
                patch.id = customer.id
                const arr: PatchableArrayAutoEncoder<CustomerPrivate> = new PatchableArray()
                arr.addPatch(patch)
                await this.patchCustomers(arr)
            }
        }))
    }

    async downloadExcel(getAllCustomers: boolean) {
        var customers: CustomerPrivate[];
        var wholesalers: WholesalerPrivate[];
        var customerOrders: Order[][] = [];
        this.isLoading = true;

        if(getAllCustomers) {
            const responseC = await Session.shared.authenticatedServer.request({
                method: "GET",
                path: "/dashboard/customers",
                decoder: new ArrayDecoder(CustomerPrivate as Decoder<CustomerPrivate>)
            })
            customers = responseC.data;

            const responseW = await Session.shared.authenticatedServer.request({
                method: "GET",
                path: "/dashboard/wholesalers",
                decoder: new ArrayDecoder(WholesalerPrivate as Decoder<WholesalerPrivate>)
            })
            wholesalers = responseW.data;
        } else {
            customers = this.customers;
            wholesalers = [this.wholesaler];
        }

        for (const customer of customers) {
            const responseO = await Session.shared.authenticatedServer.request({
                method: "GET",
                path: "/dashboard/orders/" + customer.id,
                decoder: new ArrayDecoder(Order as Decoder<Order>)
            })
            customerOrders.push(Object.create({
                customer: customer,
                orders: responseO.data
            }));
        }

        exportCustomerStats(customers, wholesalers, customerOrders, this.$i18n, this.isAdmin);
        this.isLoading = false;
    }
}
</script>

<style lang="scss">
@use '~@restofrit/scss/base/text-styles.scss' as *;

.customers-view {

    > main {
        > h4 {
            @extend .style-h4;
        }
    }

    .input-with-icon {
        margin: 10px 0;
    }

    .button {
        margin-top: 20px;
        margin-right: 5px;
    }

    .loading-cursor {
        cursor: wait;
    }

    .loading-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.8);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 1000;

        p {
            margin-top: 20px;
            font-size: 18px;
            color: #333;
        }
    }
}

</style>